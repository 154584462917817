import React from "react"

const ProductsDE = () => <>
  <h2>Produkte</h2>
  <p className="bigger-text text-center">
    Unser Team arbeitet bereits an ökologischen Produkten und
    Verfahrensentwicklung neuer Generation für die Automobil-, Bahn-, Flug- und Metallindustrie sowie für den
    Schiffbau.</p>
  <br /><br /><br /><br /><br /><br />
</>

export default ProductsDE
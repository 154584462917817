import React from "react"
import ProductsEN from "./products.en"
import ProductsDE from "./products.de"
import ProductsPL from "./products.pl"

const ProductsContent = ({ lang }) => {
  if (lang === "de") {
    return <ProductsDE />
  } else if (lang === "en") {
    return <ProductsEN />
  }
  return <ProductsPL />
}

export default ProductsContent
import React from "react"

const ProductsPL = () => <>
  <h2>Produkty</h2>
  <p className="bigger-text text-center"> Obecnie nasz zespół opracowuje przyjazne dla środowiska, nowej generacji
    farby dla branży
    motoryzacyjnej, kolejowej, przemysłu stoczniowego, lotnictwa i przemysłu metalowego..</p>
  <br /><br /><br /><br /><br /><br />
</>

export default ProductsPL